body {
  background-color: #d3d3d3;
}

.Privacy h1 {
  text-align: left;
  font-weight: bold;
  font-size: xx-large;
  margin-bottom: 1rem;
  margin-top: 2rem;
}

.Privacy h2 {
  text-align: left;
  font-weight: bold;
  font-size: x-large;
  margin-bottom: .5rem;
  margin-top: 2rem;
}

.Privacy h3 {
  text-align: left;
  font-weight: bold;
  font-size: large;
  margin-bottom: .5rem;
  margin-top: 2rem;
}

.Privacy p {
  padding-bottom: 1rem;
}

.Privacy ul {
  list-style: disc;
  margin-left: 2rem;
}